import { ENABLE_BIRDI_SELECT } from 'gatsby-env-variables';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Button from 'ui-kit/button/button';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';
import ToastBox from 'ui-kit/toast-box/toast-box';

import AutoRefillBadge from 'components/medicine-cabinet-cart/prescription-information/auto-refill-badge/auto-refill-badge';

import { formatPrice } from 'schema/price.schema';

import { CartOrderRx } from 'types/cart';

import { getPhoneNumber } from 'util/globalVariables';

import useCart from 'hooks/useCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import { PrescriptionInformationProps } from '../types';
import './prescription-information.style.scss';

export const PrescriptionInformation: React.FC<PrescriptionInformationProps> = ({ onRemoveOrder }) => {
    const { t } = useTranslation();
    const { cartData } = useCart();
    const { mainUserIsCaregiver } = usePrescriptionFlow();

    const formatBirdiSavings = useCallback(
        (item: CartOrderRx): string => {
            if (!item.cartPrice) return '';

            const hasDiscount =
                !!item.cartPrice?.discount && item.cartPrice?.discount > 0 && item.cartPrice.showStrikeThruPricing;

            if (hasDiscount) {
                return t('components.medicineCabinetCart.prescriptionInfo.save', {
                    save: formatPrice(item.cartPrice?.discount)
                });
            } else {
                return '';
            }
        },
        [t]
    );

    const handleRemoveOrder = (rxNumber: string) => {
        if (typeof onRemoveOrder === 'function') {
            onRemoveOrder(rxNumber);
        }
    };

    return (
        <>
            <Container className="cart-prescription" fluid>
                {cartData?.patients.map((patient) =>
                    patient.cartRxs.map((item) => {
                        const hasDiscount =
                            !!item.cartPrice?.discount &&
                            item.cartPrice?.discount > 0 &&
                            item.cartPrice.showStrikeThruPricing;

                        const showBirdiPriceIcon = item.cartPrice?.hasKnownPrice && item.cartPrice?.showBirdiCashPrice;

                        const showAutoRefillFlag = item.autoRefillEnabled && patient.planEligibleForAutoRefill;
                        const rxDetails = item.rxDetails.prescriptionDetail;
                        const hasBirdiSelect = item.isBirdiSelect && ENABLE_BIRDI_SELECT;

                        return (
                            <Row key={rxDetails.rxNumber} className="pb-2 pb-md-4 cart-prescription--item">
                                <Col xs={12} className="mb-2">
                                    {mainUserIsCaregiver && (
                                        <h3 className="cart-prescription--patient-name">
                                            {`${patient.firstName} ${patient.lastName}`}
                                        </h3>
                                    )}
                                </Col>
                                <Col xs={7} sm={6} md={8} className="d-flex align-items-stretch flex-column">
                                    <div className="cart-prescription--product-name">
                                        <h3 className="cart-prescription--header">{rxDetails?.dispensedProductName}</h3>
                                        {hasBirdiSelect && (
                                            <div className="cart-prescription--birdi-select">
                                                <PlanBadge variant="birdi-select-blue" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="cart-prescription--product-info">
                                        <div className="cart-prescription--product-info--rx-number">
                                            Rx #{rxDetails.rxNumber}
                                        </div>
                                        {rxDetails?.fillQuantity && (
                                            <div className="cart-prescription--product-info--content">
                                                {t('pages.cart.quantity', {
                                                    fillQuantity: rxDetails?.fillQuantity
                                                })}
                                            </div>
                                        )}
                                        {(rxDetails?.dispensedProductStrength ||
                                            rxDetails?.dispensedProductUnitOfMeasure) && (
                                            <div className="cart-prescription--product-info--content">
                                                {rxDetails?.dispensedProductStrength}
                                                {rxDetails?.dispensedProductUnitOfMeasure}
                                            </div>
                                        )}
                                        {rxDetails?.dispensedProductDosageForm && (
                                            <div className="cart-prescription--product-info--content dosage">
                                                {rxDetails?.dispensedProductDosageForm}
                                            </div>
                                        )}
                                    </div>

                                    <div className="d-none d-md-flex align-items-baseline ">
                                        {showAutoRefillFlag && (
                                            <div className="mt-2">
                                                <AutoRefillBadge
                                                    text={t(
                                                        'components.medicineCabinetCart.prescriptionInfo.autoRefill'
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={5} sm={6} md={4} className="text-right text-lg-right">
                                    <div className="d-flex flex-column">
                                        {/* Pricing logic */}
                                        {!!item.cartPrice && item.cartPrice?.hasKnownPrice ? (
                                            <>
                                                {hasDiscount && (
                                                    <div className="cart-prescription--patient-price-line">
                                                        ${item.cartPrice?.awpPrice}
                                                    </div>
                                                )}
                                                <div className="cart-prescription--patient-price-total">
                                                    {formatPrice(item.cartPrice?.finalPrice)}
                                                </div>
                                                {/* Est.Copay - just for insurance patients */}
                                                {item.isPrimaryPlanInsurance && !item.cartPrice?.showBirdiCashPrice && (
                                                    <div className="cart-prescription--patient-price-info">
                                                        {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="prescription-item-no-price">
                                                {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}
                                            </div>
                                        )}

                                        {showBirdiPriceIcon && (
                                            <div>
                                                {/* Birdi price */}
                                                <div className="cart-prescription--patient-price-info">
                                                    <BirdiPriceIcon className="cart-prescription--patient-price-info-icon" />
                                                    <div>
                                                        {t(
                                                            'components.medicineCabinetCart.prescriptionInfo.birdiPrice'
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Discount price */}
                                                <div className="cart-prescription--patient-price-saving">
                                                    {formatBirdiSavings(item)}
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-lg-0 d-flex justify-content-end">
                                            <Button
                                                type="button"
                                                variant="text"
                                                className="p-0 mb-3 mt-2 left-n1 ml-lg-auto btn-remove text-capitalize btn-underline no-min-width"
                                                label={t('pages.cart.remove')}
                                                onClick={() => handleRemoveOrder(rxDetails.rxNumber)}
                                                dataGAFormName="addMorePrescriptions"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="d-flex align-items-baseline">
                                        {showAutoRefillFlag && (
                                            <div className="mt-2">
                                                <AutoRefillBadge
                                                    text={t(
                                                        'components.medicineCabinetCart.prescriptionInfo.autoRefill'
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                {!!item.disclaimerTranslationKey && (
                                    <Col className="mt-3" sm={12} lg={'auto'}>
                                        <div className="cart-prescription--price-disclaimer">
                                            <ToastBox icon="default" variant="info">
                                                <div>
                                                    <Trans
                                                        i18nKey={item.disclaimerTranslationKey}
                                                        values={{
                                                            phoneNumber: getPhoneNumber({
                                                                isEnd:
                                                                    item?.disclaimerTranslationKey ===
                                                                    'pages.cart.rxItemErrorMessageDiscount'
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </ToastBox>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        );
                    })
                )}
            </Container>
        </>
    );
};
