// General
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

//  Ui-kit
import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

import ShippingAddressChangeModalContent from 'display-components/shipping-address-change-modal';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import { CartShipping } from 'components/cart/cart-shipping.component';
import { CartTotal } from 'components/cart/cart-total/cart-total.component';
import HealthConditions from 'components/cart/health-conditions/health-conditions.component';
import { PrescriptionInformation } from 'components/cart/review/prescription-information.component';
import CartLayout from 'components/layouts/cart/cart.layout';
import PaymentMethods from 'components/payment-methods/payment-methods.component';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';

// States
import {
    accountCreditCardsSelector,
    accountHasCreditCardsSelector,
    accountProfileAddressesSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine
} from 'state/cart/cart.routines';
import { cartIsBusySelector } from 'state/cart/cart.selectors';
import { setMedicineCabinetShouldRefresh } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

// Types
import { CreditCardPayload } from 'types/credit-card';
import { OrderBillShip } from 'types/order-prescription';
import { PaymentMethodsProps } from 'types/payment';

import { isAxiosError } from 'util/axiosClient';
// Util
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_ID } from 'util/cart';
import { TrackCheckoutStep, TrackInputError } from 'util/google_optimize/optimize_helper';
import storageHelper from 'util/storageHelper';

//  Hooks
import useCart from 'hooks/useCart';
import useHealthConditions from 'hooks/useHealthConditions';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';
import useProfile from 'hooks/useProfile';

// Modal content
import UpdateCartModalContent, { FailureUpdateCartModalContent } from '../intra-page-items/_cart-update-modal-item';
// Styles
import './review.style.scss';

const ReviewOrder = ({ data }: { data: GatsbyTypes.CartReviewDataQuery }) => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { cartData, patientsInCart, prescriptionsInCart, changeCartShippingMethod } = useCart();
    const { getAddressCards } = useProfile();
    const { isDataLoaded, mainUserIsCaregiver, mainUserHasInsurance, mainUserHasMembership } = usePrescriptionFlow();
    const { isAnyCartPatientMissingHealthConditions } = useHealthConditions();

    // // States
    const [errorStatus, setErrorStatus] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cartPageTracked, setCartPageTracked] = useState(false);
    const [noInitialPaymentMethods, setNoInitialPaymentMethods] = useState(false);
    const [showShippingWarning, setShowShippingWarning] = useState(true);

    // Selector
    const isCartBusy = useSelector(cartIsBusySelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const hasCreditCards = useSelector(accountHasCreditCardsSelector);

    // Memoized Data
    const cartIsEmpty = useMemo(() => cartData?.cartItems === 0, [cartData?.cartItems]);
    const addresses: AddressCardProps[] = useMemo(
        () => getAddressCards(profileAddresses),
        [getAddressCards, profileAddresses]
    );
    const isShippingAddressChangeFlagSet = storageHelper.local.getShippingAddressChangePriceWarning();

    const orderBillShip = useMemo(() => cartData?.orderAddress, [cartData]);

    const cartDataLoaded = useMemo(() => cartData !== undefined, [cartData]);

    const cartPaymentCardSeqNumValid = useMemo(() => !!cartData?.paymentMethod, [cartData]);

    const disableSubmitOrder = useMemo(
        () =>
            orderBillShip?.patientBillAddressSeq === null ||
            orderBillShip?.patientShipAddressSeq === null ||
            !cartPaymentCardSeqNumValid ||
            isAnyCartPatientMissingHealthConditions ||
            isSubmitting,
        [orderBillShip, cartPaymentCardSeqNumValid, isAnyCartPatientMissingHealthConditions, isSubmitting]
    );

    const trackCartStep2 = () => {
        if (!cartPageTracked) {
            TrackCheckoutStep({
                stepName: 'checkout',
                step: '2',
                carts: cartData?.patients || [],
                prescriptions: prescriptionsInCart || [],
                t: t,
                shippingCost: `${cartData?.shipMethodPrice}`,
                accountHasInsurance: mainUserHasInsurance
            });
            setCartPageTracked(true);
        }
    };

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
        storageHelper.local.setShippingAddressChangePriceWarning();
        setShowShippingWarning(false);
    };

    const handleOpenShippingAddressChangeModal = () => {
        dispatch(
            openModalComponent({
                title: t('modals.shippingAddressChange.title'),
                headerIcon: 'warning',
                variation: 'legacy',
                hasDefaultFooter: true,
                hasModalHeader: true,
                hasCustomHeader: true,
                hasCustomContent: true,
                isCloseable: false,
                customDialogClassName: 'shipping-address-change-modal',
                isCentered: true,
                content: <ShippingAddressChangeModalContent />,
                continueButtonLabel: t('modals.shippingAddressChange.cta'),
                onContinue: handleCloseModal,
                onClose: handleCloseModal
            })
        );
    };

    // This effect checks if the cart is empty and set setCartIsEmpty true or false
    useEffect(() => {
        if (cartDataLoaded === true) {
            trackCartStep2();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartDataLoaded]);

    useEffect(() => {
        if (errorStatus) {
            TrackInputError(null, 'Form Submit', t('pages.reviewOrder.errors.orderError'), 'ReviewOrder');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorStatus]);

    const defaultCreditCard = useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0
            ? allPaymentData.find((card) => card.defaultCard)
            : undefined;
    }, [allPaymentData]);

    useEffect(() => {
        if (
            noInitialPaymentMethods &&
            !cartIsEmpty &&
            !cartData?.orderAddress?.paymentCardSeqNum &&
            hasCreditCards &&
            isDataLoaded
        ) {
            if (defaultCreditCard) {
                const updatedPayment = {
                    ...orderBillShip,
                    paymentCardSeqNum: defaultCreditCard.cardSeqNum
                };

                dispatch(
                    cartUpdatePaymentRoutine.trigger({
                        ...updatedPayment,
                        onSuccess: () => {
                            setNoInitialPaymentMethods(false);
                        }
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hasCreditCards,
        cartIsEmpty,
        orderBillShip,
        defaultCreditCard,
        cartData?.orderAddress,
        noInitialPaymentMethods,
        isDataLoaded
    ]);

    useEffect(() => {
        if (!hasCreditCards) {
            setNoInitialPaymentMethods(true);
        }
    }, [hasCreditCards]);
    const handleShipToAddressClick = (address: AddressCardProps) => {
        const editAddress = profileAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        const updatedAddress = {
            ...orderBillShip,
            patientBillAddressSeq: editAddress?.addressSeqNum,
            patientShipAddressSeq: editAddress?.addressSeqNum,
            dependentAddressSeqNum: mainUserIsCaregiver
                ? orderBillShip?.dependentAddressSeqNum
                : editAddress?.addressSeqNum
        };

        dispatch(
            cartUpdateShippingRoutine.trigger({
                ...updatedAddress,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            onClose: () => {
                                if (mainUserHasMembership && !isShippingAddressChangeFlagSet && showShippingWarning) {
                                    handleOpenShippingAddressChangeModal();
                                }
                            },
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        if (
                                            mainUserHasMembership &&
                                            !isShippingAddressChangeFlagSet &&
                                            showShippingWarning
                                        ) {
                                            handleOpenShippingAddressChangeModal();
                                        }
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCartError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handlePaymentMethodSelectionChange = useCallback<NonNullable<PaymentMethodsProps['onCardSelectionChange']>>(
        (newSelectedCard: CreditCardPayload) => {
            if (!newSelectedCard || !orderBillShip) {
                return;
            }
            const newCardSeqNum: string | null = newSelectedCard.cardSeqNum
                ? newSelectedCard.cardSeqNum.toString()
                : null;

            const updatedOrderBillShip: OrderBillShip = {
                ...orderBillShip,
                paymentCardSeqNum: newCardSeqNum,
                dependentPaymentSeqNum: mainUserIsCaregiver ? orderBillShip.dependentPaymentSeqNum : newCardSeqNum
            };

            dispatch(
                cartUpdatePaymentRoutine.trigger({
                    ...updatedOrderBillShip,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.paymentMethods.success.title')}
                                        body={t('modals.paymentMethods.success.description')}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.paymentMethods.success.cta'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCart'
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <FailureUpdateCartModalContent area={t('modals.updateCart.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCartError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, orderBillShip, mainUserIsCaregiver, t]
    );

    const handleSubmitOrderClick = () => {
        const cartPrescriptions = prescriptionsInCart || [];
        setErrorStatus(false);
        setIsSubmitting(true);
        dispatch(
            cartCompleteOrderRoutine({
                currentShippingPrice: cartData?.shipMethodPrice,
                lineItems: cartPrescriptions,
                onSuccess: () => {
                    setIsSubmitting(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: cartData?.patients || [],
                        prescriptions: cartPrescriptions,
                        t: t,
                        shippingCost: `${cartData?.shipMethodPrice}`,
                        accountHasInsurance: mainUserHasInsurance
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: (data: unknown) => {
                    setIsSubmitting(false);
                    setErrorStatus(true);
                    if (
                        data &&
                        typeof data === 'object' &&
                        'response' in data &&
                        isAxiosError(data.response) &&
                        data.response.response?.data.SystemMessage === 'PRE-AUTH-DENIED'
                    ) {
                        showPaymentError();
                    }
                }
            })
        );
    };

    const handleShippingMethodSelection = (shipMethodId: string) => {
        changeCartShippingMethod({ shipMethodId, onFailure: showShippingError });
    };

    const handleRemoveOrder = useCallback(
        (rxNumber: string) => {
            setCartPageTracked(false);

            dispatch(
                cancelOrderLine({
                    rxNumber,
                    onSuccess: () => {
                        dispatch(setMedicineCabinetShouldRefresh(true));
                        TrackCheckoutStep({
                            stepName: 'remove',
                            step: '1',
                            carts: cartData?.patients || [],
                            prescriptions: prescriptionsInCart || [],
                            t: t,
                            shippingCost: `${cartData?.shipMethodPrice}`,
                            accountHasInsurance: mainUserHasInsurance
                        });
                    },
                    onFailure: () => {
                        showCartError();
                    }
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cartData, mainUserHasInsurance, prescriptionsInCart]
    );

    const showCartError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.cart')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const showPaymentError = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    }, [dispatch, t]);

    return (
        <CartLayout
            backgroundImage={data.backgroundImage}
            nodeTitle={t('pages.reviewOrder.title')}
            title={t('pages.reviewOrder.title')}
            eyebrowText={t('pages.reviewOrder.eyebrowText')}
            variant="review"
            isHideHeader={!!cartIsEmpty}
        >
            {(!cartIsEmpty && cartDataLoaded) || isSubmitting ? (
                <div className="cart-review">
                    <Spinner isVisible={isCartBusy} t={t} />
                    <CartReviewSectionHeader label={t('components.medicineCabinetCart.shippingMethod.title')} />
                    <CartShipping
                        handleDefaultShippingClick={() => handleShippingMethodSelection(DEFAULT_SHIPPING_ID)}
                        handleExpeditedShippingClick={() => handleShippingMethodSelection(EXPEDITED_SHIPPING_ID)}
                    />
                    <CartReviewSectionHeader
                        label={t('pages.cart.prescriptionInformation')}
                        pricingLabel={t('pages.cart.pricing')}
                    />
                    <PrescriptionInformation onRemoveOrder={handleRemoveOrder} />
                    <Container fluid>
                        <Row>
                            <Col>
                                <Button
                                    type="button"
                                    variant="text-blue"
                                    className="p-0 mt-4 text-uppercase add-prescriptions"
                                    label={t('pages.cart.addMorePrescriptions')}
                                    onClick={() => {
                                        navigate('/secure/medicine-cabinet');
                                    }}
                                    dataGAFormName="addMorePrescriptions"
                                    chevron="right"
                                    style={{
                                        lineHeight: '1.5'
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <CartTotal
                        t={t}
                        total={cartData?.total}
                        subTotal={cartData?.subTotal}
                        shipMethodPrice={cartData?.shipMethodPrice}
                        hasInsurance={mainUserHasInsurance}
                        itemHasUnknownPrice={cartData?.itemHasUnknownPrice}
                        hasMembership={mainUserHasMembership}
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.shippingAddress')} />
                    <ShippingAddresses
                        addressData={addresses}
                        onSetAsShipping={handleShipToAddressClick}
                        isProfile={false}
                        showLabels={true}
                        addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                        buttonVariant="text-blue"
                        isEmptyWarning={true}
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.paymentInformation')} />
                    <PaymentMethods
                        hasMultipleColumns
                        withNewPaymentButton
                        isProfile={false}
                        showLabel
                        onCardSelectionChange={handlePaymentMethodSelectionChange}
                        paymentData={allPaymentData ? allPaymentData : []}
                        paymentRequiredMessage={t('pages.reviewOrder.paymentMethodRequired')}
                        selectedCardSeqNum={cartData?.paymentMethod?.cardSeqNum.toString()}
                        showSelectCardRadioInput
                        showSetDefaultLink
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.healthProfile')} />
                    <HealthConditions patients={patientsInCart} />
                    <div className="cart-review__actions">
                        <Button
                            type="button"
                            className="cart-submit-button"
                            label={t('pages.reviewOrder.submit')}
                            onClick={handleSubmitOrderClick}
                            dataGAFormName="OrderPrescription"
                            dataGAFormStepName="Checkout"
                            disabled={disableSubmitOrder}
                        />
                        <Button
                            type="button"
                            variant="text"
                            className="text-cerulean cart-review__actions--back"
                            label={t('pages.reviewOrder.backToCart')}
                            onClick={() => {
                                navigate('/secure/medicine-cabinet');
                            }}
                            dataGAFormName="OrderPrescription"
                            dataGAFormStepName="Checkout"
                        />

                        <div className="cart-total__disclaimer">
                            {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {cartIsEmpty && cartDataLoaded ? (
                        <PageSection className="page-section__white">
                            <Container fluid>
                                <Row className="justify-content-center">
                                    <Col
                                        className="text-center d-flex flex-column justify-content-center align-items-center"
                                        xs={12}
                                        md={6}
                                    >
                                        <CartIcon className={'cart-icon__circle'} />
                                        <h1>{t('pages.reviewOrder.emptyCart.title')}</h1>
                                        <h3>{t('pages.reviewOrder.emptyCart.subtitle')}</h3>
                                        <div className="spacer" />
                                        <Button
                                            type="button"
                                            className="sm-full btn-bold"
                                            label={t('pages.reviewOrder.emptyCart.submit')}
                                            onClick={() => navigate('/secure/medicine-cabinet')}
                                            dataGALocation="Your shopping cart is empty."
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </PageSection>
                    ) : (
                        <Spinner isVisible={!isDataLoaded} t={t} />
                    )}
                </>
            )}
        </CartLayout>
    );
};

export default ReviewOrder;

export const query = graphql`
    query CartReviewData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImageMobile: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
