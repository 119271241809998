import { TFunction } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { formatPrice } from 'schema/price.schema';

import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import './cart-total.style.scss';

export const CartTotal = ({
    t,
    disclaimerText,
    shipMethodPrice,
    subTotal,
    total,
    itemHasUnknownPrice,
    hasMembership,
    hasInsurance
}: {
    t: TFunction<'translation'>;
    disclaimerText?: string;
    subTotal?: number;
    shipMethodPrice?: number;
    total?: number;
    hasInsurance?: boolean;
    itemHasUnknownPrice?: boolean;
    hasMembership?: boolean;
}) => {
    const { mainUserHasInsurance } = usePrescriptionFlow();

    const hasUnknownPrice = useMemo(() => itemHasUnknownPrice || !!hasMembership, [itemHasUnknownPrice, hasMembership]);

    return (
        <Container>
            <Row>
                <Col>
                    <div className="cart__cart-total">
                        <Container fluid className="cart__cart-total--details">
                            <Row className="cart__cart-total--subtotal">
                                <Col xs={6} className="cart__cart-total--title">
                                    {t('pages.cart.subTotal')}
                                    {hasUnknownPrice && mainUserHasInsurance ? <sup>*</sup> : ''}
                                </Col>
                                <Col xs={6} className="cart__cart-total--price text-right">
                                    {formatPrice(subTotal)}
                                </Col>
                            </Row>
                            <Row className="cart__cart-total--shipping">
                                <Col xs={6} className="cart__cart-total--title">
                                    {t('pages.cart.shipping')}
                                </Col>
                                <Col xs={6} className="cart__cart-total--price text-right">
                                    {formatPrice(shipMethodPrice)}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="cart__cart-total--summary">
                            <Row>
                                <Col xs={6} className="cart__cart-total--title">
                                    {t('pages.cart.estTotal')}
                                    {hasUnknownPrice && hasInsurance ? <sup>*</sup> : ''}
                                </Col>
                                <Col xs={6} className="cart__cart-total--price text-right">
                                    {formatPrice(total)}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="d-flex flex-column">
                            {disclaimerText && hasUnknownPrice && hasInsurance && (
                                <Row className="cart__cart-total--disclaimer">
                                    <ToastBox icon="default" variant="info">
                                        {disclaimerText}
                                    </ToastBox>
                                </Row>
                            )}
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
